<template>
  <div class="page-div">
    <div class="page-title-div">
      <div class="left-div" @click="goBack">
        <img src="../assets/return.png" alt="">
        <label>抽奖</label>
      </div>
      <div class="right-div">
        <label @click="onToAddresses">收获地址</label>
      </div>
    </div>
    <div class="turntable-div" :style="{width: turntableWidth}">
      <div class="title-div">
        <img src="../assets/horn.png" alt="">
        <label>每次抽奖将消耗10积分</label>
      </div>
      <div class="turntable-div-box" :style="boxSize">
        <div class="rotary-table">
          <template v-for="(item, index) in awards">
            <div v-if="item.mark === 1" class="award award-default" :key="index">
              <div class="award-img">
                <img :src="`${$imgBaseURL}/${item.icon}`" alt="">
              </div>
              <label>{{item.name}}</label>
              <div class="mask" v-show="item.index === current"></div>
            </div>
            <div v-else class="award award-special" :key="index">
              <label>谢谢</label>
              <label>参与</label>
              <div class="mask" v-show="item.index === current"></div>
            </div>
            <div class="start-btn" v-if="index === 3" @click="start">
              <label>开始抽奖</label>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="tips-div">温馨提示：中奖后会有工作人员与您联系，请耐心等待！</div>
    <div class="notes-btn-div">
      <input type="button" value="中奖记录" @click="onToAwardNotes">
    </div>
    <div class="confirm-div" v-if="isShowConfirmBox">
      <div class="confirm-box-div" :style="confirmBoxSize">
        <div class="title-div">{{ confirmTitle }}</div>
        <div class="content-div">{{ confirmContent }}</div>
        <div class="msg-div">{{ confirmMsg }}</div>
        <div class="btn-div">
          <input type="button" value="确定" @click="onEnter">
        </div>
      </div>
    </div>

<!--    <div id="box">-->
<!--      <ul id="con1" ref="con1" :class="{anim:animate === true}">-->
<!--        <li v-for='item in items'>{{item.name}}</li>-->
<!--      </ul>-->
<!--    </div>-->
  </div>
</template>

<script>
import { getAwardItems, getAddressCount, getLuckDraw } from "../common/api";

export default {
  name: "Lottery",
  data() {
    return {
      width: document.documentElement.clientWidth,
      turntableWidth: "0px",
      boxSize: {
        width: "0px",
        height: "0px"
      },
      confirmBoxSize: {
          width: "0px",
          height: "0px"
      },

      // animate:false,
      // items:[
      //   { name: '1' },
      //   { name: '2' },
      //   { name: '3' },
      //   { name: '4' },
      //   { name: '5' }
      // ],

      awards: [],
      awardObject: [],
      current: -1, // 当前索引值
      speed: 200, // 时间->速度
      diff: 15, // 基数
      time: 0, // 当前时间戳
      timer: null,
      award: {}, // 抽中的奖品
      isInit: false,
      isAddress: false,
      isLoading: false,
      isShowConfirmBox: false,
      confirmTitle: "",
      confirmContent: "",
      confirmMsg: ""
    };
  },
  created() {
    let width = this.width - Math.ceil(this.width * 0.15);
    this.turntableWidth = `${width}px`;
    this.boxSize.width = `${width * 0.96}px`;
    this.boxSize.height = `${width * 0.96}px`;
    this.confirmBoxSize.width = `${this.width}px`;
    this.confirmBoxSize.height = `${this.width}px`;
    this.initResources();

    // setInterval(this.test,3000)
  },
  methods: {
    goBack() {
      window.android.goBack();
    },
    onToAddresses() {
      this.$router.push({ name: "Addresses" });
    },
    onToAwardNotes() {
      this.$router.push({ name: "AwardNotes" });
    },

    // test() {
    //   this.scroll({name: "--" + this.random(1, 100) + "--"});
    // },
    // scroll(item){
    //   this.animate=true; // 因为在消息向上滚动的时候需要添加css3过渡动画，所以这里需要设置true
    //   setTimeout(()=>{ // 这里直接使用了es6的箭头函数，省去了处理this指向偏移问题，代码也比之前简化了很多
    //     this.items.push(item); // 将数组的第一个元素添加到数组的
    //     this.items.shift(); //删除数组的第一个元素
    //     this.animate=false; // margin-top 为0 的时候取消过渡动画，实现无缝滚动
    //   },500)
    // },
    // random(m, n) {
    //   return Math.floor(Math.random() * (n - m + 1) + m)
    // },

    getSort(index) {
      switch (index) {
        case 0: return 0;
        case 1: return 1;
        case 2: return 2;
        case 3: return 4;
        case 4: return 7;
        case 5: return 6;
        case 6: return 5;
        case 7: return 3;
        default: return -1;
      }
    },
    initResources() {
      getAwardItems().then(data => {
        if(Array.isArray(data)){
          let awards = [];
          data.forEach(item => {
            let info = {id:item.id, index: item.index, name: item.name, mark: item.mark, icon: item.icon};
            awards[this.getSort(info.index)] = info;
            this.awardObject[item.index] = info;
          });
          this.awards = awards;
        }
        this.isInit = true;
      }).catch(error => {
        alert("页面初始化失败，请稍后重试");
        window.android.goBack();
      });
    },
    async start() {
      if(!this.isInit || this.isLoading){
        return;
      }

      let result = await this.doGetAddressCount();
      if(result){
        if(this.isAddress){
          this.doLuckDraw();
        }else{
          this.$layer.msg("请添加收货地址");
        }
      }
    },
    doLuckDraw() {
      this.isLoading = true;
      getLuckDraw().then(data => {
        this.award.id = data.id;
        this.award.name = data.name;
        this.award.mark = data.mark;

        this.time = Date.now();
        this.speed = 200;
        this.diff = 15;
        if(this.current === -1){
          this.current = 0;
        }

        this.move();
      }).catch(error => {
        this.isLoading = false;
        if(Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")){
          this.$layer.msg(error.data.msg);
        }else{
          this.$layer.msg("操作失败，请稍后重试");
        }
      });
    },
    move() {
      this.timer = setTimeout(() => {
        ++this.current;
        if(this.current > 7){
          this.current = 0;
        }

        // 若抽中的奖品id存在，则开始减速转动
        if(this.award.id && (Date.now() - this.time) / 1000 > 2){
          this.speed += this.diff; // 转动减速
          // 若转动时间超过4秒，并且奖品id等于小格子的奖品id，则停下来
          if((Date.now() - this.time) / 1000 > 4 && this.award.id === this.awardObject[this.current].id){
            clearTimeout(this.timer);
            setTimeout(() => {
              this.confirmTitle = this.award.mark === 1 ? "恭喜您获得" : "";
              this.confirmContent = this.award.mark === 1 ? this.award.name : "很遗憾没有抽中";
              this.confirmMsg = this.award.mark === 1 ? "" : "下次努力";
              this.isShowConfirmBox = true;
              this.isLoading = false;
            }, 0);
            return;
          }
        }else{
          // 若抽中的奖品不存在，则加速转动
          this.speed -= this.diff;
        }

        this.move();
      }, this.speed);
    },
    async doGetAddressCount() {
      return await getAddressCount().then(data => {
        this.isAddress = typeof(data) === "number" && data > 0;
        return true;
      }).catch(error => {
        if(Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")){
          this.$layer.msg(error.data.msg);
        }else{
          this.$layer.msg("操作失败，请稍后重试");
        }
        return false
      });
    },
    onEnter() {
      this.isShowConfirmBox = false
    }
  }
};
</script>

<style lang="less" scoped>
//#box{
//  width: 85%;
//  height: 100px;
//  overflow: hidden;
//  padding: 0 0.2rem;
//  border: 1px solid black;
//}
//.anim{
//  transition: all 0.5s;
//  margin-top: -30px;
//}
//#con1 li{
//  list-style: none;
//  line-height: 30px;
//  height: 30px;
//}

.page-div {
  width: 100%;
  height: 100%;
  background-color: #DD4F42;
  display: flex;
  flex-direction: column;
  align-items: center;
  .page-title-div {
    width: 100%;
    height: 0.35rem;
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .left-div {
      margin-left: 0.05rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        width: 0.28rem;
        height: 0.28rem;
      }
      label {
        font-size: 0.16rem;
        font-weight: bold;
      }
    }
    .right-div {
      margin-right: 0.05rem;
      font-size: 0.14rem;
    }
  }
  .turntable-div {
    padding: 0.1rem 0.15rem 0.15rem 0.15rem;
    border-radius: 0.1rem;
    background-color: #FFD93F;
    margin-top: 0.3rem;
    .title-div {
      margin-bottom: 0.05rem;
      height: 0.3rem;
      border-radius: 0.15rem;
      font-size: 0.14rem;
      color: #FFFFFF;
      background-color: #CC5407;
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        width: 0.22rem;
        height: 0.22rem;
        margin-left: 0.15rem;
        margin-right: 0.1rem;
      }
    }
    .turntable-div-box {
      margin: 0 auto;
      padding: 0.1rem 0.05rem 0.05rem 0.05rem;
      border-radius: 0.05rem;
      background-color: #CC5407;
      .rotary-table {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        .award {
          width: calc(100% / 3 - 0.05rem);
          height: calc(100% / 3 - 0.05rem);
          text-align: center;
          border-radius: 0.10rem;
          position: relative;
          .mask {
            width: 100%;
            height: 100%;
            border-radius: 0.10rem;
            background-color: #FFD93F;
            opacity: 0.66;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
        .award-special {
          background-color: #FBFE8A;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: #EE5405;
          font-weight: bold;
          font-size: 0.16rem;
        }
        .award-default {
          background: linear-gradient(#FFFEF5,#FDFCDA);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .award-img {
            width: 60%;
            height: 60%;
            margin-bottom: 0.15rem;
            img {
              width: auto;
              height: auto;
              max-width: 100%;
              max-height: 100%;
            }
          }
          label {
            font-weight: bold;
            font-size: 0.16rem;
          }
        }
        .start-btn {
          width: calc(100% / 3 - 0.05rem);
          height: calc(100% / 3 - 0.05rem);
          border-radius: 0.10rem;
          background: linear-gradient(#FEBD98,#F83624);
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          label {
            font-size: 0.18rem;
            font-weight: bold;
            color: #FFFFFF;
          }
        }
      }
    }
  }
  .tips-div {
    line-height: 0.5rem;
    color: #FFFFFF;
    font-size: 0.13rem;
  }
  .notes-btn-div {
    margin-top: 0.5rem;
    input {
      width: 1.6rem;
      height: 0.40rem;
      font-size: 0.16rem;
      font-weight: bold;
      color: #CC5407;
      background-color: #FBED59;
      border: none;
      border-radius: 0.05rem;
    }
  }
  .confirm-div {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.6);
    .confirm-box-div {
      position: fixed;
      top: 17%;
      left: 0;
      text-align: center;
      background: url(../assets/confirm-bg.png) center/100% no-repeat;
      .title-div {
        padding: 1.35rem 0 0.15rem 0;
        font-weight: bold;
        font-size: 0.16rem;
        color: #FBED59;
      }
      .content-div {
        width: 1.3rem;
        padding: 0.1rem 0;
        margin: 0 auto;
        color: #FFFFFF;
        font-size: 0.15rem;
        border-radius: 0.05rem;
        background-color: rgba(0,0,0,0.2);
      }
      .msg-div {
        color: #FFFFFF;
        font-size: 0.15rem;
        margin-top: 0.1rem;
      }
      .btn-div {
        margin-top: 1.1rem;
        input {
          width: 1.3rem;
          height: 0.4rem;
          border-radius: 0.05rem;
          border: none;
          color: #FFFFFF;
          font-size: 0.18rem;
          background-color: #D9030F;
        }
      }
    }
  }
}
</style>
